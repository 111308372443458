import React, { useEffect, useState } from "react";
import {
  getCaseDetailsByCaseIdApi,
  getListPlansForCaseApi,
  getTSByCaseIdApi,
} from "./api/api";
import { doctorMapper, patientMapper } from "./helpers/mappers";
import { errorToaster } from "./helpers/toaster";
import { FormGroup, Label, Input, Button } from "reactstrap";
import { getParams } from "./helpers/getParams";
function FetchCaseById(props) {
  const { stateObject, setStateObject } = props;
  let params = getParams();
  /* --------------------------------------------------------------------------------*/
  /* get treatment setups by case id on key press  */
  /* --------------------------------------------------------------------------------*/
  const handleKeyDown = (e) => {
    if (e.target.value !== "" && e.key === "Enter") {
      setStateObject((prevState) => ({
        ...prevState,
        caseId: e.target.value.trim(),
      }));
      GetTreatmentsPlanByCaseId();
    }
  };
  useEffect(() => {
    if (params["caseId"]) {
      setStateObject((prevState) => ({
        ...prevState,
        caseId: params["caseId"],
      }));
      GetTreatmentsPlanByCaseId();
    }
  }, []);

  /* --------------------------------------------------------------------------------*/
  /* Get Treatments Plan By CaseId */
  /* --------------------------------------------------------------------------------*/
  const GetTreatmentsPlanByCaseId = async () => {
    const regexMFG = /^[a-zA-Z0-9]{10}\.[a-zA-Z0-9]{4}$/;
    const regexAccess = /^[a-zA-Z0-9]{11}$/;
    const caseId = stateObject.caseId || params["caseId"];
    if (caseId === undefined) {
      setStateObject((prevState) => ({
        ...prevState,
        caseId: "",
        showError: true,
        isEonMFG: false,
      }));
      return;
    }

    try {
      if (caseId) {
        // check if caseId has a dot '.' MFG
        if (caseId.indexOf(".") !== -1) {
          // EON MFG
          setStateObject((prevState) => ({ ...prevState, isEonMFG: true }));
          const response = await getCaseDetailsByCaseIdApi(caseId);
          const data = response.data ? response.data : response;
          const patientData = patientMapper(data);
          const doctorData = doctorMapper(data);
          const caseUuid = data.uuid;
          const ownerTypeVariable = data.ownerType;
          const tsStatus = "NEW";
          setStateObject((prevState) => ({
            ...prevState,
            patient: patientData,
            doctor: doctorData,
            caseUuid: caseUuid,
            ownerType: ownerTypeVariable,
          }));
          const ts = await getListPlansForCase(
            caseUuid,
            ownerTypeVariable,
            tsStatus
          );
          if (ts[0].status !== "NEW") {
            errorToaster(
              "This case doesn't have TS with pending conversion state."
            );
            return;
          }
          if (ts.length !== 0) {
            setStateObject((prevState) => ({
              ...prevState,
              treatmentSetups: ts,
              treatmentSetupsUuid: ts[0].uuid,
            }));
          }
        } else {
          //eon access
          setStateObject((prevState) => ({ ...prevState, isEonMFG: false }));
          const tsStatus = "Pending conversion";
          const res = await getTSByCaseId(caseId, tsStatus);
          if (res[0].state !== "Pending conversion") {
            errorToaster(
              "This case doesn't have TS with pending conversion state."
            );
            return;
          }
          setStateObject((prevState) => ({
            ...prevState,
            caseUuid: res[0].id,
          }));
          if (res[0].length !== 0) {
            setStateObject((prevState) => ({
              ...prevState,
              treatmentSetups: res,
              treatmentSetupsUuid: res[0].id,
            }));
          }
        }
      }
    } catch (error) {
      errorToaster("Record not found.");
      console.error(error);
    }
  };
  const getListPlansForCase = (caseUuid, ownerTypeVariable, tsStatus = "") => {
    const params = {
      op: "EQ",
      value: tsStatus,
      key: "status",
    };
    return getListPlansForCaseApi(caseUuid, ownerTypeVariable, params)
      .then((res) => {
        let ts = (res.data.data ? res.data.data : res.data)
          .filter((elem) => elem.status === tsStatus)
          .map((elem) => {
            return {
              ...elem,
              label: `${elem.title}`,
              elem: elem.uuid,
            };
          });
        return ts;
      })
      .catch((err) => console.log(err));
  };
  const getTSByCaseId = (caseId, tsStatus) => {
    return getTSByCaseIdApi(caseId).then((res) => {
      setStateObject((prevState) => ({
        ...prevState,
        patient: res.data?.patient ?? {},
        doctor: res.data?.doctor ?? {},
      }));
      let ts = res.data.treatment_setups
        .filter((value, index) => value.state === tsStatus)
        .map((value, index) => {
          return {
            ...value,
            label: `${value.id} - ${value.front_title}`,
            value: value.id,
          };
        });
      return ts;
    });
  };
  return (
    <div className="search-grid">
      <FormGroup className="mb-2">
        <Label className="">UID</Label>
        <Input
          value={params["caseId"] ? params["caseId"] : stateObject.caseId}
          name="uid"
          id="uid"
          type="text"
          placeholder="Enter case uid number"
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            setStateObject((prevState) => ({
              ...prevState,
              chosenFile: [],
              chosenTs: [],
            }));
            if (e.target.value === "") {
              setStateObject((prevState) => ({ ...prevState, caseId: "" }));
            } else {
              setStateObject((prevState) => ({
                ...prevState,
                caseId: e.target.value.trim(),
              }));
            }
          }}
          disabled={stateObject.uploading}
        />
      </FormGroup>
      <div>
        <Button
          size="sm"
          className={`h-100  align-self-end mt-3 ${
            !stateObject.uploading ? "active-btn1" : "select-btn-dis"
          }`}
          onClick={() => {
            GetTreatmentsPlanByCaseId();
          }}
          disabled={stateObject.uploading}
        >
          Load Treatment Setups
        </Button>
      </div>
      {stateObject.showError && !stateObject.caseId && (
        <div className="error ">this field is required</div>
      )}
    </div>
  );
}

export default FetchCaseById;
