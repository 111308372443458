import {
  computeBoundsTree,
  disposeBoundsTree,
  acceleratedRaycast,
} from "three-mesh-bvh";
import * as THREE from "three";
import { scene } from "./ClickManager";
import { teethMaterial } from "./BiteJumpViewer";

THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

let isOcclusalOn = false;
export let gumGroup;
const vectorOne = new THREE.Vector3(1, 1, 1);

const lineGroup = new THREE.Group();
lineGroup.name = "occ_group";
const lineGeometry = new THREE.BufferGeometry();
const lineOcc = new THREE.LineSegments(
  lineGeometry,
  new THREE.LineBasicMaterial({ color: 0xe91e63 })
);
const bgLineHeavyContactMaterial = new THREE.LineBasicMaterial({
  color: "red",
  side: THREE.DoubleSide,
  polygonOffset: true,
  polygonOffsetFactor: 1,
  polygonOffsetUnits: 1,
  blending: THREE.CustomBlending,
});

export function prepareOcclusal(_gumGroup) {
  if (!gumGroup) {
    _gumGroup.add(lineGroup);
  }
  gumGroup = _gumGroup;
}

export function RefreshOcclusal() {
  removeOcclusal();
  calculateOcclusal();
}

function DrawContact(mesh1, mesh2, material) {
  ////////////////////////////////////////////////////////
  let geometry;
  let matrix2to1 = new THREE.Matrix4()
    .copy(mesh1.matrixWorld)
    .invert()
    .multiply(mesh2.matrixWorld);
  const edge = new THREE.Line3();
  const results = [];
  mesh1.geometry.boundsTree.bvhcast(mesh2.geometry.boundsTree, matrix2to1, {
    intersectsTriangles(triangle1, triangle2) {
      if (triangle1.intersectsTriangle(triangle2, edge)) {
        const { start, end } = edge;
        results.push(start.x, start.y, start.z, end.x, end.y, end.z);
      }
    },
  });
  if (results.length) {
    geometry = lineOcc.geometry.clone();
    const posArray = geometry.attributes.position.array;
    if (posArray.length < results.length) {
      geometry.dispose();
      geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(new Float32Array(results), 3, false)
      );
    } else {
      posArray.set(results);
    }

    geometry.setDrawRange(0, results.length / 3);
    geometry.attributes.position.needsUpdate = true;

    lineOcc.geometry = geometry.clone();
    const bgLine = lineOcc.clone();
    bgLine.material = material;

    material.renderOrder = 3;
    lineGroup.add(bgLine);
  }
}

function setLinePosition() {
  lineGroup.rotation.copy(gumGroup.children[1].rotation);
  lineGroup.position.copy(gumGroup.children[1].position);
}
function calculateOcclusal() {
  lineGeometry.setFromPoints([
    new THREE.Vector3(0, 1, 0),
    new THREE.Vector3(0, -1, 0),
  ]);

  const lowerScaleCacher = gumGroup.children[1].scale.clone();
  const upperScaleCacher = gumGroup.children[0].scale.clone();

  gumGroup.children[1].scale.copy(vectorOne);
  gumGroup.children[0].scale.copy(vectorOne);

  scene.updateMatrixWorld(true);
  for (let t = 0; t < gumGroup.children[1].children.length; t++) {
    if (gumGroup.children[1].children[t].name.charAt(0) !== "l") {
      continue;
    }
    let lowerTooth = gumGroup.children[1].children[t];
    if (!lowerTooth.geometry.boundsTree) {
      lowerTooth.geometry.computeBoundsTree();
    }
    for (let i = 0; i < gumGroup.children[0].children.length; i++) {
      if (gumGroup.children[0].children[i].name.charAt(0) !== "u") {
        continue;
      }
      let upperTooth = gumGroup.children[0].children[i];
      if (!upperTooth.geometry.boundsTree) {
        upperTooth.geometry.computeBoundsTree();
      }

      //lowerTooth.position.y += heavyContactThreshold;
      scene.updateMatrixWorld(true);
      DrawContact(lowerTooth, upperTooth, bgLineHeavyContactMaterial);
    }
  }
  setLinePosition();
  gumGroup.children[1].scale.copy(lowerScaleCacher);
  gumGroup.children[0].scale.copy(upperScaleCacher);
}
function removeOcclusal() {
  const childrenToRemove = [];
  gumGroup?.children?.forEach((child) => {
    if (child.name === "occ_group") {
      child.children.forEach((lineChild) => {
        childrenToRemove.push(lineChild);
      });
      childrenToRemove.forEach((lineChild) => child.remove(lineChild));
    }
  });
}
export function switchOcclusal(isOn) {
  isOcclusalOn = isOn;
  lineGroup.visible = isOn;
  if (isOn) {
    RefreshOcclusal();
  } else {
    removeOcclusal();
  }
  updateTeethMaterialToOcclusal();
}

function updateTeethMaterialToOcclusal() {
  if (isOcclusalOn) {
    teethMaterial.transparent = true;
    teethMaterial.opacity = 0.7;
    teethMaterial.side = THREE.FrontSide;
    teethMaterial.needsUpdate = true;
  } else {
    teethMaterial.transparent = false;
    teethMaterial.side = THREE.DoubleSide;
    teethMaterial.opacity = 1;
    teethMaterial.needsUpdate = true;
  }
}
