import React from "react";
import { ZoomIn, ZoomOut, Pan, Rotate } from "../helpers/Icons";

function Movement({
  leftMouse,
  setLeftMouse,
  camera,
  cameraControlsRef,
  setOrientation,
}) {
  return (
    <div id={"bounty_movement_controls"}>
      <div
        className={`bounty_nav_icon ${
          leftMouse === "pan" ? "views_active" : ""
        }`}
        onClick={() => {
          setOrientation("pan");
          setLeftMouse("pan");
        }}
      >
        {/* <ToolTip text={"Pan"} /> */}
        <Pan />
      </div>

      <div
        className={`bounty_nav_icon ${
          leftMouse === "rotate" ? "views_active" : ""
        }`}
        onClick={() => {
          setOrientation("rotate");
          setLeftMouse("rotate");
        }}
      >
        {/* <ToolTip text={"Rotate"} /> */}
        <Rotate />
      </div>

      <div
        className="bounty_nav_icon zoomIn"
        onClick={() => {
          cameraControlsRef.zoom((camera.zoom = +0.25), true);
        }}
      >
        {/* <ToolTip text={"Zoom In"} /> */}
        <ZoomIn />
      </div>
      <div
        className="bounty_nav_icon zoomOut"
        onClick={() => {
          cameraControlsRef.zoom((camera.zoom = -0.25), true);
        }}
      >
        {/* <ToolTip text={"Zoom Out"} /> */}
        <ZoomOut />
      </div>
    </div>
  );
}

export default Movement;
