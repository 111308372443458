import React, { useEffect, useState } from "react";
import { Occ, TransBox, ArrowBtn } from "../helpers/Icons";
import { controller } from "./controller";
import { switchOcclusal } from "../Occlusal";
// import { ToolTip } from "../../shared-components/ToolTip";

function Render() {
  const [currentMode, setCurrentMode] = useState(2);
  const [toggleOcc, setToggleOcc] = useState(false);

  useEffect(() => {
    switchOcclusal(toggleOcc);
  }, [toggleOcc]);
  return (
    <div id={"bounty_render_controls"} className="d-flex gap-3">
      <div
        onClick={() => {
          controller(
            "translation-mode",
            undefined,
            "",
            undefined,
            undefined,
            undefined,
            undefined,
            undefined
          );
          setCurrentMode(1);
        }}
        style={{
          width: "85px",
          padding: "0px",
        }}
        className={`bounty_nav_icon ${currentMode === 1 ? "views_active" : ""}`}
      >
        {/* {<ToolTip text={"Show Attachment"} />} */}
        <ArrowBtn />
        <div>Arrow button</div>
      </div>

      <div
        onClick={() => {
          controller(
            "rotation-mode",
            undefined,
            "",
            undefined,
            undefined,
            undefined,
            undefined,
            undefined
          );
          setCurrentMode(2);
        }}
        style={{
          padding: "0px",
          width: "80px",
        }}
        className={`bounty_nav_icon ${currentMode === 2 ? "views_active" : ""}`}
      >
        {/* {<ToolTip text={"Show Attachment"} />} */}
        <TransBox />
        <div>Trans box</div>
      </div>

      <div
        onClick={() =>
          controller(
            "occ-view",
            undefined,
            "",
            undefined,
            undefined,
            undefined,
            setToggleOcc,
            toggleOcc
          )
        }
        style={{
          width: "50px",
          padding: "0px",
        }}
        className={`bounty_nav_icon ${toggleOcc ? "views_active" : ""}`}
      >
        {/* { (
        <ToolTip
          text={
            !toggle.hasIpr
              ? "The current TS doesn't have any IPR information"
              : "Show IPR"
          }
        />
      )} */}
        <Occ />
        <div>Occ</div>
      </div>
    </div>
  );
}

export default Render;
