import React from "react";
import {
  Left,
  Lower,
  Right,
  Upper,
  Bottom,
  Top,
  Front,
} from "../helpers/Icons";
//import { ToolTip } from "../../shared-components/ToolTip";
import { controller } from "./controller";

function Views({
  view,
  setView,
  MainGLB,
  toggleArch,
  setToggleArch,
  cameraControlsRef,
}) {
  return (
    <div id={"bounty_views_controls"}>
      <div
        onClick={() =>
          MainGLB?.children[0].children.length === 0
            ? null
            : controller(
                "upper",
                cameraControlsRef,
                view,
                setView,
                setToggleArch
              )
        }
        className={`bounty_nav_icon ${
          toggleArch.showUpper
            ? "views_active"
            : MainGLB?.children[0].children.length === 0
            ? "bounty_icon_disabled"
            : ""
        }`}
      >
        {/* <ToolTip text={"Show Upper"} /> */}
        <Upper />
        <div>Upper</div>
      </div>

      <div
        onClick={() =>
          MainGLB?.children[0].children.length === 0
            ? null
            : controller("top", cameraControlsRef, view, setView, setToggleArch)
        }
        className={`bounty_nav_icon ${
          view === "top"
            ? "views_active"
            : MainGLB?.children[0].children.length === 0
            ? "bounty_icon_disabled"
            : ""
        }`}
        style={{
          padding: "0px",
          width: "100%",
        }}
      >
        {/* <ToolTip text={"Top View"} /> */}
        <Top />
        <div>Top</div>
      </div>

      <div
        onClick={() =>
          controller("right", cameraControlsRef, view, setView, setToggleArch)
        }
        className={`bounty_nav_icon ${view === "right" ? "views_active" : ""}`}
      >
        {/* <ToolTip text={"Right View"} /> */}
        <Right />
        <div>Right</div>
      </div>

      <div
        onClick={() =>
          controller("front", cameraControlsRef, view, setView, setToggleArch)
        }
        className={`bounty_nav_icon ${view === "front" ? "views_active" : ""}`}
        style={{
          padding: "0px",
          width: "100%",
        }}
      >
        {/* <ToolTip text={"Front View"} /> */}
        <Front />
        <div>Front</div>
      </div>

      <div
        onClick={() =>
          controller("left", cameraControlsRef, view, setView, setToggleArch)
        }
        className={`bounty_nav_icon ${view === "left" ? "views_active" : ""}`}
      >
        {/* <ToolTip text={"Left View"} /> */}
        <Left />
        <div>Left</div>
      </div>

      <div
        onClick={() =>
          MainGLB?.children[1].children.length === 0
            ? null
            : controller(
                "bottom",
                cameraControlsRef,
                view,
                setView,
                setToggleArch
              )
        }
        className={`bounty_nav_icon ${
          view === "bottom"
            ? "views_active"
            : MainGLB?.children[1].children.length === 0
            ? "bounty_icon_disabled"
            : ""
        }`}
        style={{
          padding: "0px",
          width: "100%",
        }}
      >
        {/* <ToolTip text={"Bottom View"} /> */}
        <Bottom />
        <div>Bottom</div>
      </div>

      <div
        onClick={() =>
          MainGLB?.children[1].children.length === 0
            ? null
            : controller(
                "lower",
                cameraControlsRef,
                view,
                setView,
                setToggleArch
              )
        }
        className={`bounty_nav_icon ${
          toggleArch.showLower
            ? "views_active"
            : MainGLB?.children[1].children.length === 0
            ? "bounty_icon_disabled"
            : ""
        }`}
      >
        {/* <ToolTip text={"Show Lower"} /> */}
        <Lower />
        <div>Lower</div>
      </div>
    </div>
  );
}

export default Views;
