import React, { useEffect, useState } from "react";
import FetchCaseById from "./fetchCaseById";
import TSDetails from "./tsDetails";
import TSDropDown from "./tsDropDown";
import Upload from "./Upload";
import JSZip from "jszip";
import Loader from "./loader";
import ShowTreatmentPlan from "./showTreatmentPlan";
import ShowConfirmationModal from "./showConfirmationModal";
import BiteJumpViewer from "./BiteJumpViewer";

function TreatmentPlanInfo() {
  const [stateObject, setStateObject] = useState({
    caseId: "",
    uploading: false,
    converting: false,
    showError: false,
    errors: [],
    caseUuid: "",
    treatmentSetups: [],
    treatmentSetupsUuid: "",
    showTS: false,
    chosenTs: {},
    doctor: {},
    patient: {},
    ownerType: false,
    isEonMFG: false,
    key: {},
    url: {},
    showConfirmation: false,
    updateTs: false,
    chosenFile: [],
    xmlFile: [],
    showBitejumpViewer: false,
    updatedZipFolder: "",
    start_bitejump: false,
    finish_bitejump: false,
    NumberOfUpperSteps: 0,
    NumberOfLowerSteps: 0,
  });
  const [massage, setMassage] = useState("");

  useEffect(() => {
    if (window.parent) {
      window.addEventListener("message", function (event) {
        if (
          event.data.type === "webpackWarnings" ||
          event.data.type === "webpackClose" ||
          !event.data
        ) {
          return;
        } else {
          setMassage(event?.data);
        }
      });
    }
  }, []);

  useEffect(() => {
    console.log(stateObject.caseId.length);
  }, [stateObject.caseId]);

  const handleValueChange = (event) => {
    setStateObject((prevState) => ({
      ...prevState,
      start_bitejump: !prevState.start_bitejump,
    }));
  };
  const getNumberOfSteps = async () => {
    if (Object.keys(stateObject.chosenFile).length > 0) {
      const zip = new JSZip();
      await zip.loadAsync(stateObject.chosenFile);
      //console.log(zip.files, "files read");
      let upper = 0;
      let lower = 0;
      setStateObject((prevState) => ({
        ...prevState,
        numberOfUpperSteps: 0,
        numberOfLowerSteps: 0,
      }));
      Object.keys(zip.files).forEach((key) => {
        if (key.includes("diff")) {
          if (key.includes("Upper")) {
            upper += 1;
          } else {
            lower += 1;
          }
        }
      });

      setStateObject((prevState) => ({
        ...prevState,
        NumberOfUpperSteps: upper,
        NumberOfLowerSteps: lower,
      }));
    }
  };
  useEffect(() => {
    getNumberOfSteps();
    console.log(stateObject.NumberOfLowerSteps, stateObject.NumberOfUpperSteps);
  }, [
    stateObject.NumberOfUpperSteps,
    stateObject.NumberOfLowerSteps,
    stateObject.chosenFile,
  ]);
  return (
    <>
      <Loader stateObject={stateObject} />
      <div className="header">Converter</div>
      {stateObject.showTS && (
        <ShowTreatmentPlan
          massage={massage}
          stateObject={stateObject}
          setStateObject={setStateObject}
        />
      )}
      <ShowConfirmationModal
        stateObject={stateObject}
        setStateObject={setStateObject}
      />
      <div className="main_container">
        <FetchCaseById
          stateObject={stateObject}
          setStateObject={setStateObject}
        />
        <TSDropDown stateObject={stateObject} setStateObject={setStateObject} />
        <TSDetails stateObject={stateObject} />
        <div className="flex gap-[8px] pt-4">
          <div
            style={{
              color:
                (stateObject.NumberOfLowerSteps === 0 ||
                  stateObject.NumberOfUpperSteps === 0) &&
                stateObject.caseId.length === 0
                  ? "gray"
                  : "black",
            }}
          >
            With bite jump:{" "}
          </div>

          <input
            style={{
              cursor:
                (stateObject.NumberOfLowerSteps === 0 ||
                  stateObject.NumberOfUpperSteps === 0) &&
                stateObject.caseId.length === 0
                  ? "not-allowed"
                  : "pointer",
            }}
            disabled={
              stateObject.NumberOfLowerSteps === 0 ||
              stateObject.NumberOfUpperSteps === 0
            }
            type="checkbox"
            id="bitejump"
            onChange={handleValueChange}
          />
          {(stateObject.NumberOfLowerSteps === 0 ||
            stateObject.NumberOfUpperSteps === 0) &&
            stateObject.caseId.length > 0 && (
              <div style={{ color: "red" }}>
                Bite jump is not available with one arch treatment
              </div>
            )}
        </div>
        <Upload stateObject={stateObject} setStateObject={setStateObject} />
        {stateObject.showBitejumpViewer && (
          <BiteJumpViewer
            stateObject={stateObject}
            setStateObject={setStateObject}
          />
        )}
      </div>
    </>
  );
}

export default TreatmentPlanInfo;
