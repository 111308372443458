import React from "react";
import { Undo, Redo, Reset } from "./helpers/Icons";

import { useModal } from "./hooks/useModal";
import { reset } from "./ClickManager";

function URR() {
  const { ModalContainer: ResetModal, toggleModal: toggleReset } = useModal();

  return (
    <div className="bounty_urr absolute">
      {/* <div
        id="undo"
    
        className="bounty_undo"
      >
        <Undo />
      </div>
      <div
        id="redo"
     
        className="bounty_redo"
      >
        <Redo />
      </div> */}
      <ResetModal
        title="Reset Modifications"
        primaryActionText="Reset"
        primaryAction={() => {
          reset();
          toggleReset();
        }}
      >
        Are you sure you want to reset all of your modifications to the
        treatment plan?
      </ResetModal>
      <div id="reset" onClick={toggleReset} className="bounty_reset">
        <Reset />
      </div>
    </div>
  );
}

export default URR;
