import * as THREE from "three";
import { selectedGum } from "./ClickManager";

export let hasGizmos = false;

export const controlActionType = {
  NONE: 0,
  TRANSLATION: 1,
  ROTATION: 2,
};

const movementAmount = 0.1;
const rotationAmount = 0.0017444;
export let currentMode = controlActionType.ROTATION;

export const movementDirectionName = {
  RIGHT: "Right",
  LEFT: "Left",
  UP: "Up",
  DOWN: "Down",
  FORWARD: "Forward",
  BACKWARD: "Backward",
};

export function prepareBiteJumpSimulationGizmos1(groupObject) {
  prepareForRotation(groupObject);
  prepareForTranslation(groupObject);
  hasGizmos = true;
}

function prepareForRotation(groupObject) {
  const offset = 2;
  const box = new THREE.Box3();
  box.setFromObject(groupObject, true);

  const geometry = new THREE.SphereGeometry(2.5);
  const materialX = new THREE.MeshStandardMaterial({
    color: 0xff0000,
    side: THREE.FrontSide,
  });
  const materialY = new THREE.MeshStandardMaterial({
    color: 0x00ff00,
    side: THREE.FrontSide,
  });
  const materialZ = new THREE.MeshStandardMaterial({
    color: 0x0000ff,
    side: THREE.FrontSide,
  });

  const size = new THREE.Vector3();
  const center = new THREE.Vector3();
  box.getCenter(center);
  box.getSize(size);
  size.multiplyScalar(0.5);

  const x = new THREE.Vector3(size.x + offset, 0, 0);
  const y = new THREE.Vector3(0, size.y + offset, 0);
  const z = new THREE.Vector3(0, 0, size.z + offset);

  const PNG_X = new THREE.TextureLoader().load("arrow-right-circle.png");

  const geometryArrow = new THREE.PlaneGeometry(1, 1);
  const materialArrow = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    map: PNG_X,
    side: THREE.DoubleSide,
    transparent: true,
  });

  const spriteXpositive = new THREE.Mesh(geometryArrow, materialArrow);
  const spriteYpositive = new THREE.Mesh(geometryArrow, materialArrow);
  const spriteZpositive = new THREE.Mesh(geometryArrow, materialArrow);
  spriteXpositive.position.set(0, 7.5, 0);
  spriteXpositive.rotateZ(1.57);
  spriteYpositive.position.set(0, 0, 7.5);
  spriteYpositive.rotateY(-1.57);
  spriteZpositive.position.set(7.5, 0, 0);

  spriteXpositive.scale.multiplyScalar(10);
  spriteYpositive.scale.multiplyScalar(10);
  spriteZpositive.scale.multiplyScalar(10);

  const spriteXnegative = spriteXpositive.clone();
  const spriteYnegative = spriteYpositive.clone();
  const spriteZnegative = spriteZpositive.clone();
  spriteXnegative.position.multiplyScalar(-1);
  spriteYnegative.position.multiplyScalar(-1);
  spriteZnegative.position.multiplyScalar(-1);
  spriteXnegative.scale.multiplyScalar(-1);
  spriteYnegative.scale.multiplyScalar(-1);
  spriteZnegative.scale.multiplyScalar(-1);

  spriteXpositive.name = "+";
  spriteYpositive.name = "+";
  spriteZpositive.name = "+";
  spriteXnegative.name = "-";
  spriteYnegative.name = "-";
  spriteZnegative.name = "-";

  const sphereXpositive = new THREE.Mesh(geometry, materialX);
  const sphereXnegative = new THREE.Mesh(geometry, materialX);
  const sphereYpositive = new THREE.Mesh(geometry, materialY);
  const sphereYnegative = new THREE.Mesh(geometry, materialY);
  const sphereZpositive = new THREE.Mesh(geometry, materialZ);
  const sphereZnegative = new THREE.Mesh(geometry, materialZ);

  // console.log(sphereXpositive);

  sphereXpositive.add(spriteXpositive.clone());
  sphereXnegative.add(spriteXpositive.clone());
  sphereYpositive.add(spriteYpositive.clone());
  sphereYnegative.add(spriteYpositive.clone());
  sphereZpositive.add(spriteZpositive.clone());
  sphereZnegative.add(spriteZpositive.clone());

  sphereXpositive.add(spriteXnegative.clone());
  sphereXnegative.add(spriteXnegative.clone());
  sphereYpositive.add(spriteYnegative.clone());
  sphereYnegative.add(spriteYnegative.clone());
  sphereZpositive.add(spriteZnegative.clone());
  sphereZnegative.add(spriteZnegative.clone());

  sphereXpositive.scale.multiplyScalar(0.25);
  sphereYpositive.scale.multiplyScalar(0.25);
  sphereZpositive.scale.multiplyScalar(0.25);
  sphereXnegative.scale.multiplyScalar(-0.25);
  sphereYnegative.scale.multiplyScalar(-0.25);
  sphereZnegative.scale.multiplyScalar(-0.25);

  sphereXpositive.position.copy(x.clone().add(center));
  sphereXnegative.position.copy(x.multiplyScalar(-1).add(center));
  sphereYpositive.position.copy(y.clone().add(center));
  sphereYnegative.position.copy(y.multiplyScalar(-1).add(center));
  sphereZpositive.position.copy(z.clone().add(center));
  sphereZnegative.position.copy(z.multiplyScalar(-1).add(center));

  sphereXpositive.attach(createLine([center, sphereXpositive.position]));
  sphereXnegative.attach(createLine([center, sphereXnegative.position]));
  sphereYpositive.attach(createLine([center, sphereYpositive.position]));
  sphereYnegative.attach(createLine([center, sphereYnegative.position]));
  sphereZpositive.attach(createLine([center, sphereZpositive.position]));
  sphereZnegative.attach(createLine([center, sphereZnegative.position]));

  sphereXpositive.name = movementDirectionName.RIGHT;
  sphereYpositive.name = movementDirectionName.UP;
  sphereZpositive.name = movementDirectionName.FORWARD;
  sphereXnegative.name = movementDirectionName.LEFT;
  sphereYnegative.name = movementDirectionName.DOWN;
  sphereZnegative.name = movementDirectionName.BACKWARD;

  const rotationGroup = new THREE.Group();

  rotationGroup.name = "rotationGroup";

  rotationGroup.attach(sphereXpositive);
  rotationGroup.attach(sphereXnegative);
  rotationGroup.attach(sphereYpositive);
  rotationGroup.attach(sphereYnegative);
  rotationGroup.attach(sphereZpositive);
  rotationGroup.attach(sphereZnegative);

  groupObject.attach(rotationGroup);

  const helper = new THREE.Box3Helper(box, 0x000000);
  helper.name = "Box3";
  rotationGroup.add(helper);
  removeObjectFromRaycast(rotationGroup);
}
function prepareForTranslation(groupObject) {
  const offset = 2;
  const box = new THREE.Box3();
  box.setFromObject(groupObject, true);

  const PNG_X = new THREE.TextureLoader().load("arrow-right-circle.png");
  const PNG_Y = new THREE.TextureLoader().load("arrow-up-circle.png");
  const PNG_Z = new THREE.TextureLoader().load("arrow-forward-circle.png");

  const geometry = new THREE.PlaneGeometry(1, 1);
  const materialX = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    map: PNG_X,
    side: THREE.DoubleSide,
    transparent: true,
  });
  const materialY = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    map: PNG_Y,
    side: THREE.DoubleSide,
    transparent: true,
  });
  const materialZ = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    map: PNG_Z,
    side: THREE.DoubleSide,
    transparent: true,
  });

  const size = new THREE.Vector3();
  const center = new THREE.Vector3();
  box.getCenter(center);
  box.getSize(size);
  size.multiplyScalar(0.5);

  const x = new THREE.Vector3(size.x + offset, 0, 0);
  const y = new THREE.Vector3(0, size.y + offset, 0);
  const z = new THREE.Vector3(0, 0, size.z + offset);

  const spriteXpositive = new THREE.Mesh(geometry, materialX);
  const spriteXnegative = new THREE.Mesh(geometry, materialX);
  const spriteYpositive = new THREE.Mesh(geometry, materialY);
  const spriteYnegative = new THREE.Mesh(geometry, materialY);
  const spriteZpositive = new THREE.Mesh(geometry, materialZ);
  const spriteZnegative = new THREE.Mesh(geometry, materialZ);

  spriteXpositive.scale.multiplyScalar(4);
  spriteYpositive.scale.multiplyScalar(4);
  spriteZpositive.scale.multiplyScalar(4);
  spriteXnegative.scale.multiplyScalar(-4);
  spriteYnegative.scale.multiplyScalar(-4);
  spriteZnegative.scale.multiplyScalar(-4);

  spriteXpositive.position.copy(x.clone().add(center));
  spriteXnegative.position.copy(x.multiplyScalar(-1).add(center));
  spriteYpositive.position.copy(y.clone().add(center));
  spriteYnegative.position.copy(y.multiplyScalar(-1).add(center));
  spriteZpositive.position.copy(z.clone().add(center));
  spriteZnegative.position.copy(z.multiplyScalar(-1).add(center));
  spriteZpositive.rotateOnAxis(new THREE.Vector3(0, 1, 0), -1.57);
  spriteZnegative.rotateOnAxis(new THREE.Vector3(0, 1, 0), -1.57);

  spriteXpositive.name = movementDirectionName.RIGHT;
  spriteYpositive.name = movementDirectionName.UP;
  spriteZpositive.name = movementDirectionName.FORWARD;
  spriteXnegative.name = movementDirectionName.LEFT;
  spriteYnegative.name = movementDirectionName.DOWN;
  spriteZnegative.name = movementDirectionName.BACKWARD;

  const translatingGroup = new THREE.Group();
  translatingGroup.name = "translatingGroup";

  translatingGroup.attach(spriteXpositive);
  translatingGroup.attach(spriteXnegative);
  translatingGroup.attach(spriteYpositive);
  translatingGroup.attach(spriteYnegative);
  translatingGroup.attach(spriteZpositive);
  translatingGroup.attach(spriteZnegative);

  groupObject.attach(translatingGroup);
  removeObjectFromRaycast(translatingGroup);
}
export function rotateSelectedObject(selectedGum, direction) {
  const box = new THREE.Box3();
  box.setFromObject(selectedGum);
  const centerPoint = new THREE.Vector3();
  box.getCenter(centerPoint);

  rotateAroundPoint(selectedGum, centerPoint, direction, rotationAmount, true);
}
export function translateSelectedObject(selectedGum, direction) {
  selectedGum.position.add(direction.multiplyScalar(movementAmount));
}
export function turnGumGizmos(gumObject, state) {
  if (!gumObject) {
    return;
  }
  const length = gumObject.children.length;
  if (state) {
    if (currentMode === controlActionType.TRANSLATION) {
      returnObjectToRaycast(gumObject.children[length - 1]);
    } else if (currentMode === controlActionType.ROTATION) {
      returnObjectToRaycast(gumObject.children[length - 2]);
    }
  } else {
    if (currentMode === controlActionType.TRANSLATION) {
      removeObjectFromRaycast(gumObject.children[length - 1]);
    } else if (currentMode === controlActionType.ROTATION) {
      removeObjectFromRaycast(gumObject.children[length - 2]);
    }
  }
}
export function removeObjectFromRaycast(object) {
  object.visible = false;
  object.scale.multiplyScalar(0);
}
export function returnObjectToRaycast(object) {
  object.visible = true;
  object.scale.set(1, 1, 1);
}
function createLine(points) {
  const material = new THREE.LineBasicMaterial({
    color: 0x000000,
  });

  const geometry = new THREE.BufferGeometry().setFromPoints(points);

  return new THREE.Line(geometry, material);
}
export function TurnTranslationModeOn() {
  turnGumGizmos(selectedGum, false);
  currentMode = controlActionType.TRANSLATION;
  turnGumGizmos(selectedGum, true);
}
export function TurnRotationModeOn() {
  turnGumGizmos(selectedGum, false);
  currentMode = controlActionType.ROTATION;
  turnGumGizmos(selectedGum, true);
}
function rotateAroundPoint(obj, point, axis, theta, pointIsWorld = false) {
  if (pointIsWorld) {
    obj.parent.localToWorld(obj.position); // compensate for world coordinate
  }

  obj.position.sub(point); // remove the offset
  obj.position.applyAxisAngle(axis, theta); // rotate the POSITION
  obj.position.add(point); // re-add the offset

  if (pointIsWorld) {
    obj.parent.worldToLocal(obj.position); // undo world coordinates compensation
  }

  obj.rotateOnAxis(axis, theta); // rotate the OBJECT
  obj.updateMatrixWorld();
}
export function reset() {
  currentMode = controlActionType.ROTATION;
  hasGizmos = false;
}
