import React from "react";
import { Button, Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";

function ShowConfirmationModal(props) {
  const { setStateObject, stateObject } = props;
  const toggleConfirmationModal = () => {
    setStateObject((prevState) => ({
      ...prevState,
      showConfirmation: !prevState.showConfirmation,
    }));
  };
  const closeAllModals = () => {
    setStateObject((prevState) => ({
      ...prevState,
      caseId: "",
      uploading: false,
      converting: false,
      showError: false,
      errors: [],
      caseUuid: "",
      treatmentSetups: [],
      treatmentSetupsUuid: "",
      chosenTs: {},
      doctor: {},
      patient: {},
      ownerType: false,
      isEonMFG: false,
      showTS: false,
      key: {},
      url: {},
      showConfirmation: false,
      chosenFile: [],
      xmlFile: [],
    }));
  };
  return (
    <Modal
      isOpen={stateObject.showConfirmation}
      toggle={toggleConfirmationModal}
      backdrop={false}
      className="modalconfirmation"
    >
      <ModalHeader className="w-100 align-items-center">
        Confirmation to close TS viewer converter
      </ModalHeader>

      <ModalBody className="p-0">
        <div>
          <div style={{ padding: "8px 0px 8px 16px" }}>
            Are you sure you want to close the converted TS Viewer ?
            <br />
            if you click yes, you will lose the converted TS
          </div>
          <Row className="m-1">
            <Col xs="4" md="4" lg="4" className="p-2 w-50">
              <Button
                className="btn active-btn2 w-100"
                size="sm"
                onClick={() => {
                  closeAllModals();
                }}
              >
                Yes
              </Button>
            </Col>
            <Col xs="4" md="4" lg="4" className="p-2 w-50">
              <Button
                className="btn red-btn w-100 "
                size="sm"
                color="danger"
                onClick={toggleConfirmationModal}
              >
                No
              </Button>
            </Col>
          </Row>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ShowConfirmationModal;
