import {
  prepareBiteJumpSimulationGizmos1,
  hasGizmos,
  turnGumGizmos,
  currentMode,
  controlActionType,
  movementDirectionName,
  translateSelectedObject,
  rotateSelectedObject,
  reset as resetBiteJumpSimulation,
} from "./BiteJumpSimulation";
import * as THREE from "three";
import {
  RefreshOcclusal,
  gumGroup,
  prepareOcclusal,
  switchOcclusal,
} from "./Occlusal";
import { Export } from "./BJSExporter";

const clickStates = {
  DOWN: 1,
  UP: 2,
};

let currentClickState = clickStates.UP;
export let selectedGum;
export let scene;
let clickCooldown = 10;

let hasClickedRecently = false;
function getUpperGum() {
  if (scene) {
    return scene.children[0].children[0];
  }
}
function getLowerGum() {
  if (scene) {
    return scene.children[0].children[1];
  }
}
export function onPointerDown(clickInfo) {
  if (hasClickedRecently) {
    return;
  }
  hasClickedRecently = true;
  currentClickState = clickStates.DOWN;
  if (!scene) {
    assignScene(clickInfo);
  }
  if (!hasGizmos) {
    prepareBiteJumpSimulationGizmos1(getUpperGum());
    prepareBiteJumpSimulationGizmos1(getLowerGum());
  }

  const firstChar = clickInfo.intersections[0].object.name.charAt(0);

  if (firstChar === "u") {
    if (!selectedGum || selectedGum !== getUpperGum()) {
      turnGumGizmos(selectedGum, false);
      selectedGum = getUpperGum();
      turnGumGizmos(selectedGum, true);
    } else {
      turnGumGizmos(selectedGum, false);
      selectedGum = null;
    }
  } else if (firstChar === "l") {
    if (!selectedGum || selectedGum !== getLowerGum()) {
      turnGumGizmos(selectedGum, false);

      selectedGum = getLowerGum();
      turnGumGizmos(selectedGum, true);
    } else {
      turnGumGizmos(selectedGum, false);
      selectedGum = null;
    }
  } else {
    if (currentMode === controlActionType.TRANSLATION) {
      const translatingKeys = Object.keys(movementDirectionName);
      const selectedObjectName = clickInfo.intersections[0].object.name;
      for (let i = 0; i < translatingKeys.length; i++) {
        if (movementDirectionName[translatingKeys[i]] == selectedObjectName) {
          switch (movementDirectionName[translatingKeys[i]]) {
            case movementDirectionName.RIGHT:
              translateSelectedObject(selectedGum, new THREE.Vector3(1, 0, 0));
              break;
            case movementDirectionName.LEFT:
              translateSelectedObject(selectedGum, new THREE.Vector3(-1, 0, 0));
              break;
            case movementDirectionName.UP:
              translateSelectedObject(selectedGum, new THREE.Vector3(0, 1, 0));
              break;
            case movementDirectionName.DOWN:
              translateSelectedObject(selectedGum, new THREE.Vector3(0, -1, 0));
              break;
            case movementDirectionName.FORWARD:
              translateSelectedObject(selectedGum, new THREE.Vector3(0, 0, 1));
              break;
            case movementDirectionName.BACKWARD:
              translateSelectedObject(selectedGum, new THREE.Vector3(0, 0, -1));
              break;
          }
          RefreshOcclusal();
          break;
        }
      }
    } else if (currentMode === controlActionType.ROTATION) {
      const rotationKeys = Object.keys(movementDirectionName);
      const selectedObject = clickInfo.intersections[0].object;
      for (let i = 0; i < rotationKeys.length; i++) {
        if (
          movementDirectionName[rotationKeys[i]] == selectedObject.parent.name
        ) {
          const multiply = selectedObject.name == "+" ? 1 : -1;
          switch (movementDirectionName[rotationKeys[i]]) {
            case movementDirectionName.RIGHT:
              rotateSelectedObject(
                selectedGum,
                new THREE.Vector3(0, 0, 1 * multiply)
              );
              break;
            case movementDirectionName.LEFT:
              rotateSelectedObject(
                selectedGum,
                new THREE.Vector3(0, 0, 1 * multiply)
              );
              break;
            case movementDirectionName.UP:
              rotateSelectedObject(
                selectedGum,
                new THREE.Vector3(1 * multiply, 0, 0)
              );
              break;
            case movementDirectionName.DOWN:
              rotateSelectedObject(
                selectedGum,
                new THREE.Vector3(1 * multiply, 0, 0)
              );
              break;
            case movementDirectionName.FORWARD:
              rotateSelectedObject(
                selectedGum,
                new THREE.Vector3(0, 1 * multiply, 0)
              );
              break;
            case movementDirectionName.BACKWARD:
              rotateSelectedObject(
                selectedGum,
                new THREE.Vector3(0, 1 * multiply, 0)
              );
              break;
          }
          RefreshOcclusal();
          break;
        }
      }
    }
  }

  setTimeout(() => {
    hasClickedRecently = false;
  }, clickCooldown);
}
export function onPointerUp(clickInfo) {
  currentClickState = clickStates.UP;
}

export function assignScene(_mesh) {
  // console.log(clickInfo.parent);
  // let holder = clickInfo.intersections[0].object;

  // while (holder.parent) {
  //   holder = holder.parent;
  // }
  scene = _mesh.parent;
  if (!gumGroup) {
    prepareOcclusal(_mesh);
  }
}
export function resetScene() {
  scene = undefined;
  prepareOcclusal(undefined);

  selectedGum = undefined;
  resetBiteJumpSimulation();
}
export function reset() {
  if (!scene) {
    return;
  }
  scene.children[0].children[0].position.set(0, 0, 0);
  scene.children[0].children[1].position.set(0, 0, 0);

  scene.children[0].children[0].rotation.set(0, 0, 0);
  scene.children[0].children[1].rotation.set(0, 0, 0);
}
