import React, { useLayoutEffect } from "react";
import "./controls.scss";
import Movement from "./Movement";
import Views from "./Views";
import Render from "./Render";
import {
  removeObjectFromRaycast,
  returnObjectToRaycast,
} from "../BiteJumpSimulation";

function Nav({
  leftMouse,
  setLeftMouse,
  view,
  setView,
  camera,
  cameraControlsRef,
  orientation,
  setOrientation,
  MainGLB,
  toggleArch,
  setToggleArch,
}) {
  useLayoutEffect(() => {
    if (MainGLB != null) {
      if (!toggleArch.showLower && !toggleArch.showUpper) {
        returnObjectToRaycast(MainGLB.children[0]);
        returnObjectToRaycast(MainGLB.children[1]);
      } else if (toggleArch.showUpper) {
        returnObjectToRaycast(MainGLB.children[0]);
        removeObjectFromRaycast(MainGLB.children[1]);
      } else {
        removeObjectFromRaycast(MainGLB.children[0]);
        returnObjectToRaycast(MainGLB.children[1]);
      }
    }
  }, [view, toggleArch.showUpper, toggleArch.showLower]);

  return (
    <>
      <div id={"bounty_main_controls_container"}>
        <Movement
          leftMouse={leftMouse}
          setLeftMouse={setLeftMouse}
          camera={camera}
          cameraControlsRef={cameraControlsRef}
          setOrientation={setOrientation}
        />
        <div className={"bounty_views_render_container"}>
          <Views
            view={view}
            setView={setView}
            cameraControlsRef={cameraControlsRef}
            MainGLB={MainGLB}
            toggleArch={toggleArch}
            setToggleArch={setToggleArch}
          />
          <div id="bounty_divider"></div>
          <Render />
        </div>
      </div>
    </>
  );
}

export default Nav;
