import React, { useEffect, useRef, useState, useCallback } from "react";
import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import Lights from "./Lights";
import { useLoader, ambientLight } from "@react-three/fiber";
import {
  OrbitControls,
  TransformControls,
  OrthographicCamera,
  useCursor,
} from "@react-three/drei";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { GLTFExporter } from "three/addons/exporters/GLTFExporter.js";
import { Col, Row, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import Camera from "./Camera";
import { CameraControls } from "@react-three/drei";
import {
  assignScene,
  onPointerDown,
  onPointerUp,
  resetScene,
  scene,
} from "./ClickManager";
import Nav from "./controls/Nav";
import URR from "./URR";
import { Export } from "./BJSExporter";
import {
  S3Client,
  GetObjectCommand,
  PutObjectCommand,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import Dropzone from "react-dropzone";

import { errorToaster, successToaster } from "./helpers/toaster";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import { SQSClient, SendMessageCommand } from "@aws-sdk/client-sqs";
import { DynamoDBClient, GetItemCommand } from "@aws-sdk/client-dynamodb";
import JSZip from "jszip";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

let MainGLB;
export const teethMaterial = new THREE.MeshPhysicalMaterial({
  roughness: 0.4,
  reflectivity: 8,
  specularIntensity: 3,
  specularColor: 0x2e2e2e,
  emissiveIntensity: 1,
  color: "#fff9f9",
  emissive: "#303030",
  side: THREE.DoubleSide,
  flatShading: false,
  clearcoat: 0.8,
  name: "teethMaterial",
});

const gumMaterial = new THREE.MeshPhysicalMaterial({
  roughness: 0.35,
  reflectivity: 0.65,
  specularIntensity: 0.35,
  specularColor: "#ffe2e6",
  color: "#ff9090",
  emissive: "#ff93ab",
  emissiveIntensity: 0.15,
  side: THREE.DoubleSide,
  opacity: 1,
  depthWrite: true,
  transparent: true,
  name: "gumMaterial",
});

const invisableMaterial = new THREE.MeshPhysicalMaterial({
  color: "#000000",
  side: THREE.DoubleSide,
  opacity: 0,
  depthWrite: true,
  transparent: true,
  name: "invisableMaterial",
});
export function getUpperGum() {
  if (MainGLB) {
    return MainGLB.children[0];
  }
}
export function getLowerGum() {
  if (MainGLB) {
    return MainGLB.children[1];
  }
}

function BiteJumpViewer({ stateObject, setStateObject }) {
  const perspectiveCameraRef = useRef(null);
  const [modal, setModal] = useState(true);
  const [lastStep, setLastStep] = useState();
  const [view, setView] = useState("front");
  const [leftMouse, setLeftMouse] = useState("rotate");
  const [orientation, setOrientation] = useState("Portrait");
  const [toggleArch, setToggleArch] = useState({
    showUpper: false,
    showLower: false,
  });
  let intervalId = useRef(null);
  const centering_group = new THREE.Group();
  let center = { x: 0, y: 0, z: 0 };

  const cameraControlsRef = useRef(null);
  //const mainGLBRef = useRef(null);
  const credentials = fromCognitoIdentityPool({
    clientConfig: { region: process.env.REACT_APP_AWS_REGION },
    identityPoolId: process.env.REACT_APP_AWS_IDENTITYPOOLID,
  });

  const s3Client = new S3Client({
    region: process.env.REACT_APP_AWS_REGION,
    credentials,
  });

  const sqs = new SQSClient({
    region: process.env.REACT_APP_AWS_REGION,
    credentials,
  });

  const dynamo = new DynamoDBClient({
    region: process.env.REACT_APP_AWS_REGION,
    credentials,
  });
  const getSingedURL = async (url) => {
    // Create a command to get object

    const command = new GetObjectCommand({
      Bucket: process.env.REACT_APP_AWS_S3,
      Key: `${process.env.REACT_APP_ENV + "/processed/" + url.split("/")[5]}`,
    });

    // Generate the pre-signed URL
    const signedUrl = await getSignedUrl(s3Client, command, {
      expiresIn: 3600,
    });

    return signedUrl;
  };
  useEffect(() => {
    getSingedURL(stateObject.url)
      .then((result) => {
        loadGLTF(result);
      })
      .catch((error) => {
        console.error(error); // Logs any errors that may occur during the operation
      });
  }, [stateObject.url]);
  const save = (stateObject, scene, setStateObject) => {
    addToExistingZip(
      stateObject?.chosenFile,
      scene?.children[0],
      setStateObject,
      stateObject?.caseId
    );
    resetScene();
    MainGLB = undefined;
    setModal(false);
  };
  const discard = () => {
    setModal(false);
    resetScene();
    setStateObject((prevState) => ({
      ...prevState,
      showBitejumpViewer: false,
      start_bitejump: true,
    }));
    MainGLB = undefined;
  };
  const toggle = () => {
    setModal((prev) => !prev);
    resetScene();
    setStateObject((prevState) => ({
      ...prevState,
      showBitejumpViewer: false,
      start_bitejump: true,
    }));
    MainGLB = undefined;
  };

  function setCameraFocal() {
    if (!MainGLB || !cameraControlsRef) {
      return;
    }

    cameraControlsRef.current.setOrbitPoint(
      MainGLB.position.x,
      MainGLB.position.y,
      MainGLB.position.z
    );
    cameraControlsRef.current.update();
  }

  useEffect(() => {
    setCameraFocal();
  }, []);

  function prepareMesh(glbData) {
    if (MainGLB) {
      return;
    }
    const maxStepNumber = glbData.children[0].children.length;
    const tempoGroup = new THREE.Group();
    tempoGroup.name = "TP Group";

    glbData.children[0].children[
      maxStepNumber - 1
    ].children[0].geometry.computeVertexNormals();
    glbData.children[1].children[
      maxStepNumber - 1
    ].children[0].geometry.computeVertexNormals();

    tempoGroup.add(
      glbData.children[0].children[maxStepNumber - 1].children[0].clone()
    );
    tempoGroup.add(
      glbData.children[1].children[maxStepNumber - 1].children[0].clone()
    );

    for (let isLower = 0; isLower < 2; isLower++) {
      for (
        let i = 1;
        i <
        glbData.children[isLower].children[maxStepNumber - 1].children.length;
        i++
      ) {
        glbData.children[isLower].children[maxStepNumber - 1].children[
          i
        ].geometry.computeVertexNormals();
        const toothHolder =
          glbData.children[isLower].children[maxStepNumber - 1].children[
            i
          ].clone();

        for (
          let childrenIndex = 0;
          childrenIndex < toothHolder.children.length;
          childrenIndex++
        ) {
          // toothHolder.children[childrenIndex].removeFromParent();
          toothHolder.children[childrenIndex].material = invisableMaterial;
        }

        tempoGroup.children[isLower].add(toothHolder);
      }
    }
    assignMaterialsToGLB(tempoGroup);
    fixOffsetModule(tempoGroup);
    MainGLB = tempoGroup;

    setLastStep(tempoGroup);
  }
  function assignMaterialsToGLB(_MainGLB) {
    if (!_MainGLB) {
      return;
    }

    for (let isLower = 0; isLower < _MainGLB.children.length; isLower++) {
      _MainGLB.children[isLower].material = gumMaterial;
      for (
        let toothIndex = 0;
        toothIndex < _MainGLB.children[isLower].children.length;
        toothIndex++
      ) {
        _MainGLB.children[isLower].children[toothIndex].material =
          teethMaterial;
      }
    }
  }
  function fixOffsetModule(_MainGLB) {
    const box = new THREE.Box3();
    box.setFromObject(_MainGLB);
    const offset = new THREE.Vector3();
    box.getCenter(offset);

    _MainGLB.position.copy(offset.multiplyScalar(-1));
  }

  useEffect(() => {
    if (mainGLBRef.current) {
      const aabb = new THREE.Box3();
      aabb.setFromObject(centering_group);
      center = new THREE.Vector3(0, 0, 0);
      aabb.getCenter(center);
      console.log(mainGLBRef.current);
      mainGLBRef.current.position.set(-center.x, -center.y, -center.z);
      mainGLBRef.current.updateMatrixWorld();
    }
  }, []);

  const loadGLTF = (url) => {
    //console.log(url);

    const draco = new DRACOLoader();
    draco.setDecoderConfig({type: 'js'})
    draco.setDecoderPath(`${process.env.PUBLIC_URL}/Draco/`);
    const loader = new GLTFLoader();
    loader.setDRACOLoader(draco);
    loader.load(
      url,
      (gltf) => {
        prepareMesh(gltf.scene.children[0]);
      },
      // called while loading is progressing
      undefined,
      // called when loading has errors
      (error) => {
        console.error("Error loading GLTF model:", error);
      }
    );
  };

  const mainGLBRef = useCallback((node) => {
    if (node !== null) {
      assignScene(node);
    }
  }, []);

  const uploadToS3 = async (fileBlob) => {
    // if (await readXml(stateObject.xmlFile)) {
    const buffer = await fileBlob.arrayBuffer();
    const metaData = {
      caseId: stateObject?.caseId?.toString(),
      treatmentVersion: stateObject?.chosenTs?.id?.toString(),
    };
    setStateObject((prevState) => ({
      ...prevState,
      uploading: true,
    }));
    let unixTimestamp = Date.now();

    // Convert milliseconds to seconds (Unix timestamp is typically in seconds)
    let unixTimestampSeconds = Math.floor(unixTimestamp / 1000);
    const uniqueUuid = uuidv4();
    const putObjectCommand = new PutObjectCommand({
      Bucket: process.env.REACT_APP_AWS_S3,
      Key: `${process.env.REACT_APP_ENV}/raw/${
        stateObject?.caseId +
        "_" +
        stateObject?.chosenTs?.id?.toString()?.slice(-5) +
        ".zip"
      }`,
      Body: buffer,
      Metadata: metaData,
    });
    const sendMessageCommand = new SendMessageCommand({
      QueueUrl: process.env.REACT_APP_AWS_SQS_RAW,
      MessageBody: JSON.stringify({
        id: uniqueUuid,
        key: `${process.env.REACT_APP_ENV}/raw/${
          stateObject?.caseId +
          "_" +
          stateObject?.chosenTs?.id?.toString()?.slice(-5) +
          ".zip"
        }`,
        case_id: metaData.caseId,
        ts_id: metaData.treatmentVersion,
        finish_bitejump: true,
      }),
    });

    const getItemCommand = new GetItemCommand({
      TableName: "glb-converter-service-finished-jobs",
      Key: {
        id: { S: uniqueUuid },
      },
      // AttributesToGet: ["someKey"],
    });
    await s3Client
      .send(putObjectCommand)
      .then(() => {
        setStateObject((prevState) => ({
          ...prevState,
          uploading: false,
          converting: true,
        }));
      })
      .catch((err) => {
        console.error(err);
        setStateObject((prevState) => ({
          ...prevState,
          showTS: false,
          converting: false,
          uploading: false,
          converterError: true,
        }));
        errorToaster(err, true);
      });
    await sqs.send(sendMessageCommand).catch((err) => {
      console.error(err);
      setStateObject((prevState) => ({
        ...prevState,
        showTS: false,
        converting: false,
        uploading: false,
        converterError: true,
      }));
      errorToaster(err, true);
    });
    intervalId.current = setInterval(async () => {
      console.log("dynmoooooo");
      try {
        const { Item } = await dynamo.send(getItemCommand);
        if (!Item) return;
        console.log("******", Item.success);
        if (!Item.success.BOOL) throw new Error(Item.info.S);
        console.log("+++", { Item });
        clearInterval(intervalId.current);
        MainGLB = undefined;
        setStateObject((prevState) => ({
          ...prevState,
          showTS: true,
          showBitejumpViewer: false,
          converting: false,
          key: Item.key.S,
          url: `https://${process.env.REACT_APP_AWS_S3_PROCESSED}.s3.${
            process.env.REACT_APP_AWS_REGION
          }.amazonaws.com/${decodeURI(Item.key.S)}`,
        }));
        successToaster("Converted Successfully");
      } catch ({ message }) {
        clearInterval(intervalId.current);
        console.log({ message });
        setStateObject((prevState) => ({
          ...prevState,
          showTS: false,
          converting: false,
          uploading: false,
          converterError: true,
        }));
        errorToaster(message, true);
      }
    }, 1000);

    // } else {
    //   return;
    // }
  };

  const addToExistingZip = async (
    ZipFolder,
    model,
    setStateObject,
    case_id
  ) => {
    try {
      // Initialize JSZip and load the existing zip file
      const zip = new JSZip();
      await zip.loadAsync(ZipFolder);
      function getFolderNames(zip) {
        let folderName = "";

        console.log(Object.entries(zip.files)[0][0].split("/")[0]);
        folderName = Object.entries(zip.files)[0][0].split("/")[0];

        return folderName;
      }

      // Example usage
      const folderName = getFolderNames(zip);
      console.log({ folderName });
      // Export the model to GLB format
      Export(model, async (blob) => {
        try {
          // Get the blob data as an ArrayBuffer
          const arrayBuffer = await blob.arrayBuffer();
          // Add the GLB file to the zip folder
          zip.file(`${folderName}/BJS.glb`, arrayBuffer);

          // Generate the updated zip folder
          const updatedZipBlob = await zip.generateAsync({ type: "blob" });

          updatedZipBlob.name = `${ZipFolder.name.split(".zip")[0]}.zip`;
          console.log(updatedZipBlob);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(updatedZipBlob);
          link.download = `${ZipFolder.name.split(".zip")[0]}.zip`;
          // link.click();
          await uploadToS3(updatedZipBlob);
        } catch (error) {
          console.error("Error adding GLB to zip folder:", error);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Modal className="modalts" backdrop={false} isOpen={modal} toggle={toggle}>
      <ModalHeader className="w-100 align-items-center" toggle={toggle}>
        <Row className="w-100 align-items-center">
          <Col>TS Viewer</Col>
          <Col className="gap-3 text-end d-flex justify-content-end">
            <Button
              onClick={discard}
              size="sm"
              className={`h-100 float-right  bg-[#787878]`}
            >
              Discard
            </Button>
            <Button
              onClick={() => {
                save(stateObject, scene, setStateObject);
              }}
              size="sm"
              className={`h-100 float-right active-btn`}
            >
              Save Bite Jump
            </Button>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {/* <div className="position-relative"> */}
        <Nav
          leftMouse={leftMouse}
          setLeftMouse={setLeftMouse}
          view={view}
          setView={setView}
          camera={perspectiveCameraRef.current}
          cameraControlsRef={cameraControlsRef.current}
          MainGLB={MainGLB}
          orientation={orientation}
          setOrientation={setOrientation}
          toggleArch={toggleArch}
          setToggleArch={setToggleArch}
        />
        <URR />
        {/* </div> */}
        <Canvas
          gl={{
            antialias: true,
            physicallyCorrectLights: true,
            ColorManagement: true,
          }}
          shadows={true}
        >
          <CameraControls
            camera={perspectiveCameraRef.current}
            mouseButtons={{
              left: leftMouse === "rotate" ? 1 : 4,
              wheel: 16,
              right: 4,
            }}
            ref={cameraControlsRef}
            maxDistance={850}
            minDistance={25}
            minZoom={0.25}
            maxZoom={30}
            dollySpeed={0.5}
            azimuthRotateSpeed={0.5}
            polarRotateSpeed={0.5}
          />

          {MainGLB ? (
            <primitive
              object={MainGLB}
              ref={mainGLBRef}
              onPointerUp={(e) => onPointerUp(e)}
              onPointerDown={(e) => onPointerDown(e)}
            ></primitive>
          ) : (
            <></>
          )}
          <Camera perspectiveCameraRef={perspectiveCameraRef} />
          <Lights />
        </Canvas>
      </ModalBody>
    </Modal>
  );
}

export default BiteJumpViewer;
