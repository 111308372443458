import { GLTFExporter } from "three/addons/exporters/GLTFExporter.js";

// export function Export(theModel, callback) {
//   const model = theModel.clone();
//   prepareToExport(model);

//   const options = {
//     trs: false,
//     onlyVisible: true,
//     binary: true,
//   };
//   const exporter = new GLTFExporter();

//   exporter.parse(
//     [model.children[0], model.children[1]],
//     (result) => {
//       if (result instanceof ArrayBuffer) {
//         const blob = new Blob([result], { type: "application/octet-stream" });
//         callback(blob); // Call the callback function with the blob
//       } else {
//         console.log("Failed to export the model.");
//       }
//     },
//     (e) => {
//       console.error("Error exporting the model:", e);
//     },
//     options
//   );
// }

export function Export(theModel, callback) {
  const model = theModel.clone();
  prepareToExport(model);

  const options = {
    trs: false,
    onlyVisible: true,
    binary: true,
  };
  const exporter = new GLTFExporter();
  const link = document.createElement("a");
  exporter.parse(
    [model.children[0], model.children[1]],
    (result) => {
      if (result instanceof ArrayBuffer) {
        const blob = new Blob([result], { type: "application/octet-stream" });

        callback(blob); // Call the callback function with the blob
      } else {
        console.log("Failed to export the model.");
      }
    },
    (e) => {
      console.error("Error exporting the model:", e);
    },
    options
  );
}

function prepareToExport(model) {
  model.remove(model.children[2]);
  const upperGum = model.children[0];
  const lowerGum = model.children[1];
  const upperGumLength = upperGum.children.length;
  const lowerGumLength = lowerGum.children.length;

  upperGum.remove(upperGum.children[upperGumLength - 1]);
  upperGum.remove(upperGum.children[upperGumLength - 2]);

  lowerGum.remove(lowerGum.children[lowerGumLength - 1]);
  lowerGum.remove(lowerGum.children[lowerGumLength - 2]);
}
