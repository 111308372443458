import React, { useCallback, useState } from "react";
import Modal from "../shared-components/Modal";

export const useModal = () => {
  const [open, setOpen] = useState(false);

  const toggleModal = () => setOpen((prev) => !prev);

  return {
    ModalContainer: useCallback(
      ({
        title,
        className,
        customActions,
        primaryAction,
        primaryActionText,
        secondaryAction,
        secondaryActionText,
        toggle,
        children,
      }) => (
        <Modal
          {...{
            title,
            className,
            customActions,
            primaryAction,
            primaryActionText,
            secondaryAction,
            secondaryActionText,
            toggle,
            children,
          }}
          isOpen={open}
          toggle={toggleModal}
        />
      ),
      [open]
    ),
    toggleModal,
  };
};
