import { useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { Button } from "reactstrap";
import { errorToaster, successToaster } from "./helpers/toaster";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { SQSClient, SendMessageCommand } from "@aws-sdk/client-sqs";
import { DynamoDBClient, GetItemCommand } from "@aws-sdk/client-dynamodb";
import X2JS from "x2js";

const credentials = fromCognitoIdentityPool({
  clientConfig: { region: process.env.REACT_APP_AWS_REGION },
  identityPoolId: process.env.REACT_APP_AWS_IDENTITYPOOLID,
});

const s3 = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials,
});

const sqs = new SQSClient({
  region: process.env.REACT_APP_AWS_REGION,
  credentials,
});

const dynamo = new DynamoDBClient({
  region: process.env.REACT_APP_AWS_REGION,
  credentials,
});

const Upload = (props) => {
  const { stateObject, setStateObject } = props;
  const [errorMessageZip, setErrorMessageZip] = useState("");
  const [errorMessageXML, setErrorMessageXML] = useState("");
  let intervalId = useRef(null);
  const metaData = {
    caseId: stateObject?.caseId?.toString(),
    treatmentVersion: stateObject?.chosenTs?.id?.toString(),
  };
  const handleDropZip = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setErrorMessageZip("Invalid file format. Please upload a ZIP file.");
    } else {
      setStateObject((prevState) => ({
        ...prevState,
        chosenFile: acceptedFiles[0],
      }));
      setErrorMessageZip("");
    }
  };
  const handleDropXml = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setErrorMessageXML("Invalid file format. Please upload a XML file.");
    } else {
      setErrorMessageXML("");
      setStateObject((prevState) => ({
        ...prevState,
        xmlFile: acceptedFiles[0],
      }));
    }
  };

  const handleUpload = async () => {
    // if (await readXml(stateObject.xmlFile)) {
    setStateObject((prevState) => ({
      ...prevState,
      uploading: true,
    }));
    // Get the current Unix timestamp in milliseconds
    let unixTimestamp = Date.now();

    // Convert milliseconds to seconds (Unix timestamp is typically in seconds)
    let unixTimestampSeconds = Math.floor(unixTimestamp / 1000);

    const uniqueUuid = uuidv4();
    const putObjectCommand = new PutObjectCommand({
      Bucket: process.env.REACT_APP_AWS_S3,
      Key: `${process.env.REACT_APP_ENV}/raw/${
        stateObject?.caseId +
        "_" +
        stateObject?.chosenTs?.id?.toString()?.slice(-5) +
        ".zip"
      }`,
      ContentType: stateObject.chosenFile.type,
      Body: stateObject.chosenFile,
      Metadata: metaData,
    });
    const sendMessageCommand = new SendMessageCommand({
      QueueUrl: process.env.REACT_APP_AWS_SQS_RAW,
      MessageBody: JSON.stringify({
        id: uniqueUuid,
        key: `${process.env.REACT_APP_ENV}/raw/${
          stateObject?.caseId +
          "_" +
          stateObject?.chosenTs?.id?.toString()?.slice(-5) +
          ".zip"
        }`,
        case_id: metaData.caseId,
        ts_id: metaData.treatmentVersion,
        start_bitejump: stateObject.start_bitejump,
      }),
    });

    const getItemCommand = new GetItemCommand({
      TableName: "glb-converter-service-finished-jobs",
      Key: {
        id: { S: uniqueUuid },
      },
      // AttributesToGet: ["someKey"],
    });
    await s3
      .send(putObjectCommand)
      .then(() => {
        setStateObject((prevState) => ({
          ...prevState,
          uploading: false,
          converting: true,
        }));
      })
      .catch((err) => {
        console.error(err);
        setStateObject((prevState) => ({
          ...prevState,
          showTS: false,
          converting: false,
          uploading: false,
          converterError: true,
        }));
        errorToaster(err, true);
      });
    await sqs.send(sendMessageCommand).catch((err) => {
      console.error(err);
      setStateObject((prevState) => ({
        ...prevState,
        showTS: false,
        converting: false,
        uploading: false,
        converterError: true,
      }));
      errorToaster(err, true);
    });
    intervalId.current = setInterval(async () => {
      console.log("dynmoooooo");
      try {
        const { Item } = await dynamo.send(getItemCommand);
        if (!Item) return;
        console.log("******", Item.success);
        if (!Item.success.BOOL) throw new Error(Item.info.S);
        console.log("+++", { Item });
        clearInterval(intervalId.current);
        const showTS = stateObject.start_bitejump ? false : true;
        const showBitejumpViewer = stateObject.start_bitejump ? true : false;
        setStateObject((prevState) => ({
          ...prevState,
          showTS,
          showBitejumpViewer,
          converting: false,
          key: Item.key.S,
          url: `https://${process.env.REACT_APP_AWS_S3_PROCESSED}.s3.${
            process.env.REACT_APP_AWS_REGION
          }.amazonaws.com/${decodeURI(Item.key.S)}`,
        }));
        successToaster("Converted Successfully");
      } catch ({ message }) {
        clearInterval(intervalId.current);
        console.log({ message });
        setStateObject((prevState) => ({
          ...prevState,
          showTS: false,
          converting: false,
          uploading: false,
          converterError: true,
        }));
        errorToaster(message, true);
      }
    }, 1000);

    // } else {
    //   return;
    // }
  };

  const acceptedFileTypesZip = ["application/zip"];
  const acceptedFileTypesXML = ["text/xml"];

  async function readXml(xmlFile) {
    let jawUpper = 0;
    let jawLower = 0;
    try {
      setStateObject((prevState) => ({
        ...prevState,
        uploading: true,
      }));
      if (xmlFile) {
        // Convert XML to JavaScript object
        const xmlText = await xmlFile.text();
        let x2js = new X2JS();
        const result = x2js.xml2js(xmlText);
        result?.ClearAlignerProductionResources?.Molds?.Mold.forEach((step) => {
          if (step._Filename.includes("Default")) return;
          if (step._Type !== "Aligner") return;
          step._Jaw === "Upper" ? (jawUpper += 1) : (jawLower += 1);
        });
        console.log({ jawUpper, jawLower });
        if (
          jawUpper !== stateObject.treatmentSetups[0].upperSteps ||
          jawLower !== stateObject.treatmentSetups[0].lowerSteps
        ) {
          errorToaster(`
            Number of steps doesn't match:\n
            Upper steps number: ${stateObject.treatmentSetups[0].upperSteps} &
            File upper steps: ${jawUpper} Lower steps number: ${stateObject.treatmentSetups[0].lowerSteps} &
            File Lower steps: ${jawLower}`);
          setStateObject((prevState) => ({
            ...prevState,
            uploading: false,
          }));
          return false;
        } else {
          return true;
        }
      }
    } catch (error) {
      console.error("No XML file found:", error);
      errorToaster(error);
      setStateObject((prevState) => ({
        ...prevState,
        uploading: false,
      }));
    }
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="upload_container">
        <div style={{ display: "flex", gap: "16px" }}>
          <div>
            <div>*Treatment Plan file: </div>
            <div className="dropzone" style={{ marginTop: "10px" }}>
              <Dropzone
                accept={{ acceptedFileTypesZip }}
                onDrop={
                  stateObject.caseId === "" ||
                  Object.keys(stateObject.chosenTs).length === 0
                    ? null
                    : handleDropZip
                }
                disabled={
                  stateObject.caseId === "" ||
                  stateObject.converting ||
                  Object.keys(stateObject.chosenTs).length === 0
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    className={`h-100 w-100 d-inline-block`}
                  >
                    <input {...getInputProps()} />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "8px",
                      }}
                    >
                      <img alt="victor" src="./Vector.svg" />
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          marginBottom: "0px",
                        }}
                      >
                        Drag and drop a file here, or click to{" "}
                        <span style={{ color: "#295DCD" }}>select a file</span>
                      </p>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
            <div style={{ display: "flex", gap: "16px", paddingTop: "8px" }}>
              <p style={{ marginRight: "16px" }}>Supported Format .ZIP</p>
              <p
                className="mb-0"
                style={{
                  color: stateObject.chosenFile > 0 ? "#00B189" : "black",
                }}
              >
                Selected file: {stateObject.chosenFile?.name}
              </p>
              {errorMessageZip && (
                <p style={{ color: "red" }}>{errorMessageZip}</p>
              )}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", gap: "8px" }}>
          <Button
            size="sm"
            className={`btn ${
              stateObject.caseId === "" ||
              stateObject.converting ||
              Object.keys(stateObject.chosenTs).length === 0 ||
              Object.keys(stateObject.chosenFile).length === 0
                ? // ||
                  // Object.keys(stateObject.xmlFile).length === 0
                  "select-btn-dis"
                : "active-btn"
            }`}
            onClick={() => {
              handleUpload();
            }}
            disabled={
              stateObject.caseId === "" ||
              stateObject.converting ||
              Object.keys(stateObject.chosenTs).length === 0 ||
              Object.keys(stateObject.chosenFile).length === 0
              // ||
              // Object.keys(stateObject.xmlFile).length === 0
            }
          >
            Convert
          </Button>
        </div>
      </div>
    </>
  );
};

export default Upload;
