import React from "react";
import { Card, Col, Row, CardBody, CardHeader } from "reactstrap";
function TSDetails(props) {
  const { stateObject } = props;
  return (
    <Card className="card1">
      <CardHeader className="text-left"> TS Details</CardHeader>
      <CardBody>
        <Row className="align-items-center">
          <Col xs="12" md="4" lg="4">
            <div className="text-left">
              <label className="control-label title_active">
                Patient Full Name
              </label>
              {" : "}
              <label className="control-label ">
                {/* data-hj-suppress ( to suppress data on hotjar )  */}
                <p className="text-muted" data-hj-suppress>
                  {Object.keys(stateObject.chosenTs).length > 0 &&
                    stateObject.patient.full_name}
                </p>
              </label>
            </div>

            <div className="text-left">
              <label className="control-label title_active">Title</label>
              {" : "}
              <label className="control-label ">
                <p className="text-muted">
                  {Object.keys(stateObject.chosenTs).length > 0 &&
                    stateObject.chosenTs.front_title}
                </p>
              </label>
            </div>
            <div className="text-left">
              <label className="control-label title_active">
                Start Together
              </label>
              {" : "}
              <label className="control-label ">
                <p className="text-muted">
                  {Object.keys(stateObject.chosenTs).length > 0 &&
                    stateObject.chosenTs.start_together + ""}
                </p>
              </label>
            </div>
            <div className="text-left">
              <label className="control-label title_active">
                Passive Aligners
              </label>
              {" : "}
              <label className="control-label ">
                <p className="text-muted">
                  {Object.keys(stateObject.chosenTs).length > 0 &&
                    stateObject.chosenTs.passive_aligners + ""}
                </p>
              </label>
            </div>
          </Col>
          <Col xs="12" md="1" lg="1"></Col>
          <Col xs="12" md="7" lg="7">
            <Row className="text-center">
              {/* ts upper steps */}
              <Col xs="12" md="6" lg="6" className="mb-3">
                <Card className="p-2 mb-2 mt-2">
                  <label className="control-label title_card_active">
                    {Object.keys(stateObject.chosenTs).length > 0 &&
                      (stateObject.chosenTs.upper_steps || 0)}
                  </label>

                  <div>
                    <p className="text-muted mb-0">
                      {" "}
                      <strong>Upper Steps</strong>
                    </p>
                  </div>
                </Card>
              </Col>
              {/*end ts upper steps */}

              {/* ts lower steps */}
              <Col xs="12" md="6" lg="6" className="mb-3">
                <Card className="p-2 mb-2 mt-2">
                  <label className="control-label title_card_active">
                    {Object.keys(stateObject.chosenTs).length > 0 &&
                      (stateObject.chosenTs.lower_steps || 0)}
                  </label>

                  <div>
                    <p className="text-muted mb-0">
                      <strong>Lower Steps</strong>{" "}
                    </p>
                  </div>
                </Card>
              </Col>
              {/*end ts lower steps */}

              {/* ts upper overcorrection steps */}
              <Col xs="12" md="6" lg="6" className="mb-3">
                <Card className="p-2 mb-2 mt-2">
                  <label className="control-label title_card_active">
                    {Object.keys(stateObject.chosenTs).length > 0 &&
                      (stateObject.chosenTs.upper_overcorrection_steps || 0)}
                  </label>

                  <div>
                    <p className="text-muted mb-0">
                      <strong>Upper OverCorrection Steps </strong>
                    </p>
                  </div>
                </Card>
              </Col>
              {/*end ts upper overcorrection steps */}

              {/* ts lower overcorrection steps */}
              <Col xs="12" md="6" lg="6" className="mb-3">
                <Card className="p-2 mb-2 mt-2">
                  <label className="control-label title_card_active">
                    {Object.keys(stateObject.chosenTs).length > 0 &&
                      (stateObject.chosenTs.lower_overcorrection_steps || 0)}
                  </label>

                  <div>
                    <p className="text-muted mb-0">
                      <strong>Lower OverCorrection Steps</strong>
                    </p>
                  </div>
                </Card>
              </Col>
              {/*end ts lower overcorrection steps */}
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default TSDetails;
