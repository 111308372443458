import * as THREE from "three";
import {
  TurnRotationModeOn,
  TurnTranslationModeOn,
} from "../BiteJumpSimulation";
import { switchOcclusal } from "../Occlusal";
const { DEG2RAD } = THREE.MathUtils;

export const controllerActionStrings = {
  left: "left",
  right: "right",
  front: "front",
  top: "top",
  bottom: "bottom",
  upper: "upper",
  lower: "lower",
  "elastics-view": "elastics-view",
  "attachments-view": "attachments-view",
  "superImpose-view": "superImpose-view",
  "ipr-view": "ipr-view",
  "numbers-view": "numbers-view",
  "pontics-view": "pontics-view",
  "occlus-view": "occlus-view",
  "split-view": "split-view",
  "grid-view": "grid-view",
  "staging-view": "staging-view",
  "movement-view": "movement-view",
  "bolton-view": "bolton-view",
};

/**
 *
 * @param {keyof controllerActionStrings} action
 * @param {*} view
 * @param {*} setView
 * @param {*} setToggleArch


 * @returns
 */
export const controller = (
  action,
  controlsRef,
  view,
  setView,
  setToggleArch,
  toggleOcc,
  setToggleOcc
) => {
  switch (action) {
    case "left":
      if (view === "left") return;

      controlsRef.reset(true);

      controlsRef.rotate(90 * DEG2RAD, 0, true);

      setView((prev) => "left");
      setToggleArch("showUpper", false);
      setToggleArch("showLower", false);
      break;
    case "right":
      if (view === "right") return;
      controlsRef.reset(true);

      controlsRef.rotate(-90 * DEG2RAD, 0, true);

      setView((prev) => "right");

      setToggleArch((prevState) => ({
        ...prevState,
        showUpper: false,
        showLower: false,
      }));

      break;
    case "front":
      controlsRef.reset(true);

      setView((prev) => "front");
      setToggleArch((prevState) => ({
        ...prevState,
        showUpper: false,
        showLower: false,
      }));
      break;
    case "top":
      if (view === "top") return;
      controlsRef.reset(true);

      controlsRef.rotate(0, 90 * DEG2RAD, true);

      setView((prev) => "top");
      setToggleArch((prevState) => ({
        ...prevState,
        showUpper: true,
        showLower: false,
      }));

      break;
    case "bottom":
      if (view === "bottom") return;
      controlsRef.reset(true);

      controlsRef.rotate(0, -90 * DEG2RAD, true);

      setView((prev) => "bottom");
      setToggleArch((prevState) => ({
        ...prevState,
        showUpper: false,
        showLower: true,
      }));
      break;
    case "upper":
      setToggleArch((prevState) => ({
        ...prevState,
        showUpper: true,
        showLower: false,
      }));
      break;
    case "lower":
      setToggleArch((prevState) => ({
        ...prevState,
        showUpper: false,
        showLower: true,
      }));
      break;
    case "occ-view":
      setToggleOcc((prev) => !prev);
      break;
    case "translation-mode":
      TurnTranslationModeOn();
      break;
    case "rotation-mode":
      TurnRotationModeOn();
      break;

    default:
      controlsRef.reset(true);
  }
};
