import { toast } from "react-toastify";

/*--------------------------------------------------------------------------------*/
/* error Toaster                                                     */
/*--------------------------------------------------------------------------------*/
export function errorToaster(msg, converterError = false) {
  toast.error(msg, {
    position: "top-center",
    autoClose: converterError ? false : true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}
export function successToaster(msg) {
  toast.success(msg, {
    position: "top-center",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}
