import React, { useEffect, useLayoutEffect } from "react";
import { PerspectiveCamera } from "@react-three/drei";
import * as THREE from "three";

function Camera({ perspectiveCameraRef }) {
  return (
    <>
      <PerspectiveCamera
        visible={true}
        ref={perspectiveCameraRef}
        makeDefault={true}
        fov={25}
        zoom={1}
        near={1}
        far={1000}
        position={[0, 0, 200]}
      />
    </>
  );
}

export default Camera;
