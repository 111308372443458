import React from 'react'

function Loader(props) {
    const {stateObject} = props
  return (
  <div className='bounty_spinner_container'  style={stateObject.converting || stateObject.uploading ? { display: "block", zIndex: 99 } : { display: "none", zIndex: -1 }}>
     <div
        className={"bounty_spinner"}
        style={stateObject.converting || stateObject.uploading ? { display: "block", zIndex: 99 } : { display: "none", zIndex: -1 }}
      >
        <div className={"bounty_double_bounce1"}></div>
        <div className={"bounty_double_bounce2"}></div>
      </div>
      <div
        className={"bounty_loading_title"}
        style={stateObject.converting || stateObject.uploading ? { display: "block", zIndex: 99 } : { display: "none", zIndex: -1 }}
      >
        {stateObject.converting ? "Converting ...": "Uploading ..."}
      </div>
  </div>
  )
}

export default Loader